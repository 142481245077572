import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgFluidWebp from "../components/img-fluid-webp"

import "../scss/site.scss"
import "../scss/team.scss"

const TeamPage = () => {
  return (
    <Layout>
      <SEO
        title="Über uns | freie ingenieure bau"
        description="Wir begleiten Ihr Bau­vorhaben als Planer, Berater oder Gutachter – immer persönlich."
        lang="de"
      />

      <div>
        <ImgFluidWebp
          className="uk-visible@s"
          filename="img-team-title.jpg"
          alt="freie ingenieure bau - Über uns"
        />
        <ImgFluidWebp
          className="uk-hidden@s"
          filename="img-team-title-quare.jpg"
          alt="freie ingenieure bau - Über uns"
        />
      </div>

      <div className="fib-container-text">
        {/* Partner 1 */}
        <div className="fib-sp200"></div>
        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-5-12@m">
            <ImgFluidWebp
              className="fib-team-img-partner"
              filename="img-partner1-body.jpg"
              alt="freie ingenieure bau - Dr.-Ing. H. Belaschk"
            />
          </div>
          <div className="uk-width-7-12@m">
            <div className="uk-width-10-12@s uk-width-8-12@m">
              <p className="fib-ft20 fib-ft-bold fib-team-heading">
                Dr.-Ing. H. Belaschk
              </p>
              <p className="fib-ft20 fib-ft-italic">
                Zertifizierter Sachverständiger für den vorbeugenden und
                gebäudetechnischen Brandschutz (DIN EN ISO/IEC 17024)
              </p>
              <div className="fib-sp20"></div>
              <p className="fib-ft20 fib-ft-red fib-ft-medium">
                <Obfuscate
                  className="fib-text-link"
                  email="belaschk@freie-ingenieure-bau.de"
                >
                  belaschk@freie-ingenieure-bau.de
                </Obfuscate>
                <br />
                <span itemProp="telephone">
                  <a className="fib-text-link" href="tel:+493098577374">
                    T +49 30 98577374
                  </a>
                </span>
                <br />
                <span itemProp="telephone">
                  <a className="fib-text-link" href="tel:+4917623356677">
                    M +49 176 23356677
                  </a>
                </span>
              </p>
              <div className="fib-sp20"></div>
              {/* <p className="fib-ft20 fib-ft-bold fib-team-heading">Motiv</p> */}
              <p className="fib-ft20">
                &bdquo;Wichtiger als die Lösung zu wissen ist für mich, das
                Problem richtig zu erfassen. Auch komplexe Probleme lassen sich
                dadurch manchmal erschreckend einfach lösen.&ldquo;
              </p>
            </div>
            <div className="fib-sp20"></div>
            <ul uk-accordion="">
              <li>
                <a
                  className="uk-accordion-title fib-ft20 fib-ft-bold fib-team-heading"
                  href="/"
                >
                  Qualifikation / Veröffentlichung
                </a>
                <div className="uk-accordion-content">
                  <div className="fib-bg-muted fib-timeline-container">
                    <div className="fib-ft18 fib-ft-bold fib-timeline-text">
                      Qualifikation
                    </div>
                    <ul className="fib-timeline fib-timeline-red fib-ft18">
                      <li>
                        <div className="bullet big">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z"></path>
                            <circle cx="16" cy="16" r="6"></circle>
                          </svg>
                        </div>
                        Gründung &bdquo;freie ingenieure bau | Dr. Belaschk +
                        Krätschell PartGmbB&ldquo;
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Part&shy;ner in ei&shy;nem deutsch&shy;land&shy;weit
                        tä&shy;ti&shy;gen Ingenieurbüro
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Zertifizierter Sach&shy;ver&shy;stän&shy;di&shy;ger für
                        den vor&shy;beu&shy;gen&shy;den und
                        ge&shy;bäu&shy;de&shy;tech&shy;ni&shy;schen
                        Brand&shy;schutz (DIN EN ISO/IEC 17024)
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Promotion an der Leib&shy;niz Uni&shy;ver&shy;sität
                        Han&shy;nover zur Brand- und
                        Rauch&shy;si&shy;mu&shy;lation in großen Räumen
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Stipendiat des Bun&shy;des&shy;mini&shy;steriums für
                        Wirt&shy;schaft und Technologie
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Wissenschaftlicher Mit&shy;ar&shy;bei&shy;ter am
                        Insti&shy;tut für Bau&shy;physik (LUH)
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Sachverständigen&shy;tätig&shy;keit im B&shy;ereich
                        Bau&shy;dynamik und erd&shy;beben&shy;sicheres Bauen
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Projektleiter im Be&shy;reich Trag&shy;werks&shy;planung
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Freelance Software Development “Quick-EnEV”
                      </li>
                    </ul>
                    <hr />
                    <div className="fib-ft18 fib-ft-bold">
                      Veröffentlichungen in Fachbüchern/-zeitschriften
                    </div>
                    <p className="fib-ft18">
                      u. a. Bauphysik-Kalender, Der Sach&shy;ver&shy;ständige,
                      Fach&shy;zeit&shy;schrift Bau&shy;physik,
                      Brand&shy;schutz-Kalender, Fach&shy;buch
                      &bdquo;Näher&shy;ungs&shy;formeln für Spill Plumes in
                      Atrien variabler Größe&ldquo;
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="fib-sp20"></div>
          </div>
          <div className="uk-width-7-12@s"></div>
        </div>

        {/* Partner 2 */}
        <div className="fib-sp160"></div>
        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-5-12@m">
            <ImgFluidWebp
              className="fib-team-img-partner"
              filename="img-partner2-body.jpg"
              alt="freie ingenieure bau - Dr.-Ing. H. Belaschk"
            />
          </div>
          <div className="uk-width-7-12@m">
            <div className="uk-width-10-12@s uk-width-8-12@m">
              <p className="fib-ft20 fib-ft-bold fib-team-heading">
                Dipl.-Ing. Michael Krätschell
              </p>
              <p className="fib-ft20 fib-ft-italic">
                Öffentlich bestell&shy;ter und ver&shy;eidig&shy;ter
                Sach&shy;verstän&shy;diger für Schä&shy;den an Gebäu&shy;den und
                Wärme- sowie Feuchte&shy;schutz, Prüf&shy;sachver&shy;ständiger
                für ener&shy;getische Ge&shy;bäude&shy;planung
              </p>
              <div className="fib-sp20"></div>
              <p className="fib-ft20 fib-ft-blue fib-ft-medium">
                <Obfuscate
                  className="fib-text-link"
                  email="kraetschell@freie-ingenieure-bau.de"
                >
                  kraetschell@freie-ingenieure-bau.de
                </Obfuscate>
                <br />
                <span itemProp="telephone">
                  <a className="fib-text-link" href="tel:+493061644837">
                    T +49 30 61644837
                  </a>
                </span>
                <br />
                <span itemProp="telephone">
                  <a className="fib-text-link" href="tel:+4917632954749">
                    M +49 176 32954749
                  </a>
                </span>
              </p>
              <div className="fib-sp20"></div>
              {/* <p className="fib-ft20 fib-ft-bold fib-team-heading">Motiv</p> */}
              <p className="fib-ft20">
                &bdquo;Was mich bei jeder Projekt&shy;bear&shy;beitung
                moti&shy;viert gilt unab&shy;hängig von der Größe und der
                tech&shy;nischen Heraus&shy;forderung. Das
                Zwischen&shy;menschliche, der Punkt an dem Sie per&shy;sönlich
                bemer&shy;ken, dass ich Ihr Pro&shy;jekt voran&shy;gebracht
                habe.&ldquo;
              </p>
            </div>
            <div className="fib-sp20"></div>
            <ul uk-accordion="">
              <li>
                <a
                  className="uk-accordion-title fib-ft20 fib-ft-bold fib-team-heading"
                  href="/"
                >
                  Qualifikation / Veröffentlichung
                </a>
                <div className="uk-accordion-content">
                  <div className="fib-bg-muted fib-timeline-container">
                    <div className="fib-ft18 fib-ft-bold fib-timeline-text">
                      Qualifikation
                    </div>
                    <ul className="fib-timeline fib-timeline-blue fib-ft18">
                      <li>
                        <div className="bullet big">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z"></path>
                            <circle cx="16" cy="16" r="6"></circle>
                          </svg>
                        </div>
                        Gründung &bdquo;freie ingenieure bau | Dr. Belaschk +
                        Krätschell PartGmbB&ldquo;
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Partner in einem deutsch&shy;land&shy;weit täti&shy;gen
                        Ingenieurbüro
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Erweiterung der öffent&shy;lichen Bestel&shy;lung und
                        Ver&shy;eidigung um das Gebiet &bdquo;Schäden an
                        Gebäuden&ldquo;, IHK Berlin
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Vorsitzender des Ausschusses
                        &bdquo;Prüf&shy;sach&shy;verständige&ldquo;, Baukammer
                        Berlin
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Berufung in den Prüfungs&shy;aus&shy;schuss
                        &bdquo;Ener&shy;getische Gebäude&shy;planung&ldquo;,
                        Brandenburgische-Ingenieurkammer
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        öffentlich bestellter und ver&shy;eidig&shy;ter
                        Sach&shy;ver&shy;stän&shy;diger für &bdquo;Wärme- und
                        Feuchte&shy;schutz&ldquo;, IHK-Berlin
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        Prüfsachverständiger für ener&shy;getische
                        Ge&shy;bäude&shy;planung, Aner&shy;kennung durch die
                        Oberste Bau&shy;aufsichtsbehörde, Berlin
                      </li>
                      <li>
                        <div className="bullet">
                          <svg
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                          >
                            <circle
                              stroke="none"
                              cx="16"
                              cy="16"
                              r="12"
                            ></circle>
                          </svg>
                        </div>
                        zertifizierter Ener&shy;gie&shy;berater,
                        Archi&shy;tektenkammer Berlin
                      </li>
                    </ul>
                    <hr />
                    <div className="fib-ft18 fib-ft-bold">
                      Veröffentlichungen in Fachbüchern/-zeitschriften
                    </div>
                    <p className="fib-ft18">
                      u. a. Berliner Anwalts&shy;blatt, Deutsches
                      Archi&shy;tekten&shy;blatt, Bau&shy;physik-Kalen&shy;der,
                      Fach&shy;zeit&shy;schrift Der
                      Bau&shy;sach&shy;verständige, Fach&shy;buch
                      Bau&shy;schadens&shy;fälle, Fach&shy;buch Schä&shy;den
                      durch mangel&shy;haften Wärme&shy;schutz, Frank&shy;furter
                      Bau&shy;sach&shy;ver&shy;ständigen&shy;tag, Denkmalmesse
                      Leipzig
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="fib-sp20"></div>
          </div>
          <div className="uk-width-7-12@s"></div>
        </div>

        {/* 
                <div className="fib-sp160"></div>
        <div
          class="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-flex-top uk-flex-wrap-top"
          uk-grid="masonry: true"
        >
          <div>
            <ImgFluidWebp
              className=""
              filename="team_masonry_1.png"
              alt="freie ingenieure bau - Impressionen"
            />
          </div>
          <div>
            <ImgFluidWebp
              className=""
              filename="team_masonry_2.png"
              alt="freie ingenieure bau - Impressionen"
            />
          </div>
          <div>
            <ImgFluidWebp
              className=""
              filename="team_masonry_3.png"
              alt="freie ingenieure bau - Impressionen"
            />
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

export default TeamPage
